<template>
  <collapse-card :model-value="false">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Article Details
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="articleDetailsLoading"
        class="flex justify-center p-40"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div
        v-else
        class="py-2 px-4"
      >
        <div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
            <base-input
              v-model="dealSummaryData.articleDetails.searchKeyword"
              type="text"
              container-class="flex items-center"
              label="Search"
              label-class="label-w-46"
            />
            <div class="flex">
              <base-button
                variant="btn-primary"
                :disabled="!dealSummaryData.articleDetails.searchKeyword.length"
                class="mr-3"
                text="Search"
                @click.stop.prevent="fetchArticleDetails()"
              />
              <base-button
                variant="btn-link"
                text="Cancel"
                @click="resetSearch()"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mb-4">
          <base-pagination
            :model-value="dealSummaryData.articleDetails.tableData.page"
            :total-pages="dealSummaryData.articleDetails.tableData.pageCount"
            @update:modelValue="handlePageUpdate($event)"
          />
        </div>
        <brand-assurance-table
          id="InboxTable"
          v-model="dealSummaryData.articleDetails.tableData"
          root-element-class="mb-20"
          are-columns-resizable
          :show-check-box-column="false"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, ref, onMounted } from 'vue';
import useDealSummary from './dealSummary.js';
import { useStore } from 'vuex';
import { formatDate } from '@/helpers/util';

export default {
    name: 'ArticleDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue'))
    },

    setup () {
        const store = useStore();
        const { dealSummaryData } = useDealSummary();

        const resetSearch = () => {
            dealSummaryData.articleDetails.searchKeyword = '';
            fetchArticleDetails();
        };
        const handlePageUpdate = (newPage) => {
            dealSummaryData.articleDetails.tableData.page = newPage;
            fetchArticleDetails();
        };

        onMounted(() => {
            fetchArticleDetails();
        });

        const fetchedArticleDetails = ref([]);
        const articleDetailsLoading = ref(false);
        const fetchArticleDetails = async () => {
            try {
                articleDetailsLoading.value = true;
                fetchedArticleDetails.value = await store.dispatch('baLicensee/fetchDrmArticlesDetails', {
                    params: {
                        searchKeyword: dealSummaryData.articleDetails.searchKeyword,
                        page: dealSummaryData.articleDetails.tableData.page,
                        limit: dealSummaryData.articleDetails.tableData.limit
                    },
                    id: dealSummaryData.contractNumber,
                    onlyReturnValues: true
                });
                dealSummaryData.articleDetails.tableData.data = fetchedArticleDetails.value.data.map(obj => {
                    obj.startDate = formatDate(obj.startDate);
                    obj.endDate = formatDate(obj.endDate);
                    return obj;
                });
                dealSummaryData.articleDetails.tableData.pageCount = fetchedArticleDetails.value.pageCount;
                dealSummaryData.articleDetails.tableData.totalCount = fetchedArticleDetails.value.totalCount;
            } catch (err) {
                console.error(err);
            } finally {
                articleDetailsLoading.value = false;
            }
        };
        return {
            dealSummaryData,
            resetSearch,
            fetchArticleDetails,
            handlePageUpdate,
            articleDetailsLoading
        };
    }
};
</script>

<style>
  .articleDetailGrid tr td .block {
    max-width: 229px;
    overflow-x: scroll;
  }
  .articleDetailGrid tr td .block::-webkit-scrollbar {
      display: none;
  }
  .articleDetailGrid tr td .block {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
</style>
